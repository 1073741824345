<template>
  <div class="tab" data-tab="form">
    <form class="no-padding">
      <div class="row">
        <div class="col col-left col-24 col-sl-11 col-s-24">
          <h4>Детали заказа</h4>

          <div
            class="seats-row"
            :class="[selectedColumn.length > 2 ? 'three-seats' : 'two-seats']"
          >
            <div
              class="item illuminator"
              v-if="
                selectedColumn.find(
                  it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                )
              "
            ></div>
            <div
              :key="ix"
              class="item "
              :class="[
                seat.class === 'seat-business' ? 'seat-space-plus' : seat.class,
                seat.seatNumber === selected ? 'selected' : ''
              ]"
              v-for="(seat, ix) in selectedColumn"
            >
              <div class="label">{{ seat.seatNumber }}</div>
              <div class="here" v-if="seat.seatNumber === user.seat">
                <span>Сейчас вы сидите здесь</span>
              </div>
            </div>
            <div
              class="item illuminator"
              v-if="
                !selectedColumn.find(
                  it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                )
              "
            ></div>
          </div>

          <div class="restrictions">
            <strong>Ваш запрос обрабатывается:</strong>
            <p>
              Бортпроводник скоро подойдет к вам и сообщит код подтверждения
              операции, и уточнит детали оплаты.
            </p>
          </div>
        </div>
        <div
          class="col col-right col-24 col-sl-11 offset-sl-2 col-s-24 offset-s-0"
        >
          <div class="cols">
            <div class="price">
              <p>
                <strong>{{ $t(`planes.price`) }}</strong>
              </p>
              <p>
                <em>{{ price }} ₽</em>
              </p>
            </div>

            <div class="features">
              <p>
                <strong> {{ $t(`planes.features_select_seat`) }}</strong>
              </p>
              <p>
                <em>{{ selected }}</em>
              </p>
              <p class="info">
                {{ $t(`planes.type_${selectType}`) }}
              </p>
            </div>
          </div>

          <div class="field-row">
            <label for="email">Ваш email:</label>
            <input
              :class="{ is_invalid: errors.email }"
              type="email"
              name="email"
              id="email"
              v-model="form.email"
            />
            <p class="error-description" v-if="errors.email">
              Неверный формат email
            </p>
          </div>

          <div class="row">
            <div class="col-14 col-m-24">
              <div class="field-row">
                <label for="phone">Ваш телефон:</label>
                <input
                  type="text"
                  :class="{
                    is_invalid: errors.phone
                  }"
                  name="phone"
                  id="phone"
                  v-model="form.phone"
                />
                <p class="error-description" v-if="errors.phone">
                  Неверный формат телефонного номера
                </p>
              </div>
            </div>
            <div class="col-9 offset-1 col-m-24 offset-m-0">
              <div class="field-row">
                <label for="phone" class="nowrap">Код подтверждения:</label>
                <input
                  type="password"
                  :class="{ is_invalid: errors.code }"
                  name="code"
                  v-model="form.code"
                  id="password"
                />
                <p class="error-description" v-if="errors.code">
                  Неверный код
                </p>
              </div>
            </div>
          </div>

          <p class="warning">
            Пожалуйста, не закрывайте страницу до завершения операции.
          </p>

          <a href="" class="warning">Правила предоставления услуги</a>

          <div class="buttons">
            <div class="row">
              <div class="col-11">
                <input
                  type="button"
                  class="cancel"
                  value="Отменить"
                  @click.prevent="$emit('cancelOrder', true)"
                />
              </div>
              <div class="col-11 offset-2">
                <input
                  type="button"
                  class="buy"
                  value="Оплатить"
                  @click.prevent="$emit('validateAndPayment', true)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DetailsOrder",
  props: ["selected", "selectedColumn", "selectType", "price"],
  data: () => {
    return {
      errors: {
        phone: false,
        email: false,
        code: false
      },
      form: {
        phone: "",
        email: "",
        code: ""
      }
    };
  },
  computed: {
    ...mapState("user", {
      user: state => state.user
    }),
    emailValidate() {
      let emailValidator = new RegExp(
        "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?\\.)+[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?"
      );
      let validEmail = emailValidator.test(this.form.email);
      if (!validEmail) {
        return false;
      }

      return true;
    },
    phoneValidate() {
      return this.form.phone.length > 10;
    },
    validateForm() {
      if (
        this.form.email.length > 1 &&
        this.form.phone.length > 1 &&
        this.emailValidate &&
        this.phoneValidate
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async submit() {
      if (this.validateForm) {
        try {
          await this.axios.post("/api/board/checkPin", {
            seat: this.user.seat,
            typeUpgrade: this.selectType,
            guid: this.orders.find(order =>
              order.items.find(it => it.id === this.getLayoutUpgrade.id)
            ).guid,
            email: this.form.email,
            pin: +this.form.code,
            telephone: this.form.phone,
            payType: 1
          });
          this.errors.code = false;
        } catch (e) {
          this.errors.code = true;
        }
      }
    }
  }
};
</script>

<style scoped></style>
