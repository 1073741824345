var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab",attrs:{"data-tab":"form"}},[_c('form',{staticClass:"no-padding"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-left col-24 col-sl-11 col-s-24"},[_c('h4',[_vm._v("Детали заказа")]),_c('div',{staticClass:"seats-row",class:[_vm.selectedColumn.length > 2 ? 'three-seats' : 'two-seats']},[(
              _vm.selectedColumn.find(
                function (it) { return it.seatNumber.replace(/[0-9]/g, '') === 'A'; }
              )
            )?_c('div',{staticClass:"item illuminator"}):_vm._e(),_vm._l((_vm.selectedColumn),function(seat,ix){return _c('div',{key:ix,staticClass:"item ",class:[
              seat.class === 'seat-business' ? 'seat-space-plus' : seat.class,
              seat.seatNumber === _vm.selected ? 'selected' : ''
            ]},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))]),(seat.seatNumber === _vm.user.seat)?_c('div',{staticClass:"here"},[_c('span',[_vm._v("Сейчас вы сидите здесь")])]):_vm._e()])}),(
              !_vm.selectedColumn.find(
                function (it) { return it.seatNumber.replace(/[0-9]/g, '') === 'A'; }
              )
            )?_c('div',{staticClass:"item illuminator"}):_vm._e()],2),_vm._m(0)]),_c('div',{staticClass:"col col-right col-24 col-sl-11 offset-sl-2 col-s-24 offset-s-0"},[_c('div',{staticClass:"cols"},[_c('div',{staticClass:"price"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("planes.price")))])]),_c('p',[_c('em',[_vm._v(_vm._s(_vm.price)+" ₽")])])]),_c('div',{staticClass:"features"},[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("planes.features_select_seat")))])]),_c('p',[_c('em',[_vm._v(_vm._s(_vm.selected))])]),_c('p',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.$t(("planes.type_" + _vm.selectType)))+" ")])])]),_c('div',{staticClass:"field-row"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Ваш email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{ is_invalid: _vm.errors.email },attrs:{"type":"email","name":"email","id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.errors.email)?_c('p',{staticClass:"error-description"},[_vm._v(" Неверный формат email ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-14 col-m-24"},[_c('div',{staticClass:"field-row"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Ваш телефон:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],class:{
                  is_invalid: _vm.errors.phone
                },attrs:{"type":"text","name":"phone","id":"phone"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),(_vm.errors.phone)?_c('p',{staticClass:"error-description"},[_vm._v(" Неверный формат телефонного номера ")]):_vm._e()])]),_c('div',{staticClass:"col-9 offset-1 col-m-24 offset-m-0"},[_c('div',{staticClass:"field-row"},[_c('label',{staticClass:"nowrap",attrs:{"for":"phone"}},[_vm._v("Код подтверждения:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.code),expression:"form.code"}],class:{ is_invalid: _vm.errors.code },attrs:{"type":"password","name":"code","id":"password"},domProps:{"value":(_vm.form.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "code", $event.target.value)}}}),(_vm.errors.code)?_c('p',{staticClass:"error-description"},[_vm._v(" Неверный код ")]):_vm._e()])])]),_c('p',{staticClass:"warning"},[_vm._v(" Пожалуйста, не закрывайте страницу до завершения операции. ")]),_c('a',{staticClass:"warning",attrs:{"href":""}},[_vm._v("Правила предоставления услуги")]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11"},[_c('input',{staticClass:"cancel",attrs:{"type":"button","value":"Отменить"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('cancelOrder', true)}}})]),_c('div',{staticClass:"col-11 offset-2"},[_c('input',{staticClass:"buy",attrs:{"type":"button","value":"Оплатить"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('validateAndPayment', true)}}})])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"restrictions"},[_c('strong',[_vm._v("Ваш запрос обрабатывается:")]),_c('p',[_vm._v(" Бортпроводник скоро подойдет к вам и сообщит код подтверждения операции, и уточнит детали оплаты. ")])])}]

export { render, staticRenderFns }